/* import __COLOCATED_TEMPLATE__ from './navigation-action.hbs'; */
/* RESPONSIBLE TEAM: team-product-exploration */
import templateOnlyComponent from '@ember/component/template-only';
import type Action from 'embercom/objects/global-search/actions/action';
import type NavigationAction from 'embercom/objects/global-search/actions/navigation';

interface Signature {
  Element: HTMLDivElement;
  Args: {
    action: NavigationAction;
    activeItem: Action;
    executeSelectedAction(action: Action): void;
    setActiveItem(action: Action): void;
  };
}

const NavigationActionRow = templateOnlyComponent<Signature>();
export default NavigationActionRow;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'GlobalSearch::Rows::NavigationAction': typeof NavigationActionRow;
    'global-search/rows/navigation-action': typeof NavigationActionRow;
  }
}
