/* import __COLOCATED_TEMPLATE__ from './generic-file-uploader.hbs'; */
/* RESPONSIBLE TEAM: team-channels */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { task } from 'ember-concurrency-decorators';

export default class GenericFileUploader extends Component {
  @service notificationsService;
  @tracked file;
  @tracked error;
  @service intl;

  deselectFile() {
    this.file = undefined;
    this.error = undefined;
    document.getElementById('generic-uploader-file-select-input').value = '';
  }

  get shouldDisableSaveButton() {
    return !!this.error || !this.file;
  }

  get shouldShowCancelButton() {
    return !!this.file;
  }

  @action
  clickFileInput() {
    document.getElementById('generic-uploader-file-select-input').click();
  }

  @action
  onFileSelect(event) {
    let selectedFile = event.target.files.item(0);

    if (selectedFile.size > this.args.maxFileSize) {
      let maxMB = this.args.maxFileSize / 1000000;
      this.error = `${this.intl.t('generic-file-uploader.file-too-large')} ${
        Math.round(maxMB * 100) / 100
      } MB`;
    } else {
      this.error = undefined;
    }

    this.file = selectedFile;
  }

  @action
  cancel() {
    this.deselectFile();
  }

  @task({ restartable: true })
  *handleSubmit() {
    if (this.file.size > this.args.maxFileSize) {
      let error = new Error('Upload failed: file is too large');
      this.notificationsService.notifyResponseError(error, { default: error.message });
    } else {
      yield this.args.onUpload(this.file);
    }
    this.deselectFile();
  }
}
