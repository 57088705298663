/* RESPONSIBLE TEAM: unused-components */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file: 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
/* eslint-disable ember/no-actions-hash */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
/* eslint-disable ember/no-jquery */
/* eslint-disable @intercom/intercom/require-empty-tagname */
import $ from 'jquery';
import { or } from '@ember/object/computed';
import { computed } from '@ember/object';
import Component from '@ember/component';
import { ternary } from '@intercom/pulse/lib/computed-properties';
import AttributeInputInserter from 'embercom/components/mixins/attribute-input-inserter';

export default Component.extend(AttributeInputInserter, {
  value: '',
  placeholder: '',
  isDisabled: false,
  caretPosition: 0,
  canInsertAttributes: true,
  isFocused: false,
  attributePopoverIsShown: false,
  showAttributeSelector: or('isFocused', 'attributePopoverIsShown'),
  formClasses: '',
  selectorClasses: '',
  selectorIsOpenClass: ternary('attributePopoverIsShown', 'o__open', ''),
  combinedSelectorClasses: computed('selectorClasses', 'selectorIsOpenClass', function () {
    return `${this.selectorClasses} ${this.selectorIsOpenClass}`;
  }),
  errorClass: ternary('error', 'o__error', ''),
  combinedFormClasses: computed('formClasses', 'errorClass', function () {
    return `js__form-with-attributes ${this.formClasses} ${this.errorClass}`;
  }),
  didInsertElement() {
    this._super(...arguments);
    this.storeCaretPosition();
  },
  focusIn(e) {
    if ($(e.target).is('.js__form-with-attributes')) {
      this.setProperties({
        attributePopoverIsShown: false,
        isFocused: true,
      });
    }
  },
  focusOut() {
    if ($('.js__input-attribute-selector.o__hover', this.element).length) {
      this.setProperties({
        attributePopoverIsShown: true,
        isFocused: false,
      });
      return;
    }
    this.set('isFocused', false);
  },
  actions: {
    addAttribute(attributeData) {
      this.addAttributeAndFocus(attributeData, 'value');
    },
  },
});
